import { joinURL, parseURL, withLeadingSlash } from 'ufo'
import { useAppNav } from '@base/components/AppNav/useAppNav'

// middleware responsible for resolving ecom links received from TYPO3
// called on /r/:route (resolve.vue)
export default defineNuxtRouteMiddleware(async (to) => {
  const runtimeConfig = useRuntimeConfig()
  const region = runtimeConfig.public.app.region
  const { slug: _slug, type } = to.params
  const slug = Array.isArray(_slug) ? _slug[0] : _slug
  const slugDecoded = decodeURIComponent(slug)
  const { categoryLink, productLink } = useAppNav()

  const resolveProduct = async (sku: string) => {
    const data = await GqlResolveProduct({
      sku,
    })

    const url = data?.products?.items?.[0]?.canonical_url

    if (!url) {
      throw new Error('No url found')
    }

    return productLink(url)
  }

  const resolveCategory = async (path: string) => {
    const { pathname, search } = parseURL(path)
    const data = await GqlResolveCategory({
      uid: pathname,
    })

    const url = data?.categoryList?.[0]?.canonical_url

    if (!url) {
      throw new Error('No url found')
    }

    return categoryLink(url + (search ?? ''))
  }

  if (!type) {
    return navigateTo('/')
  }

  try {
    let url = ''

    if (type === 'p') {
      url = await resolveProduct(slugDecoded)
    }

    if (type === 'c') {
      url = await resolveCategory(slugDecoded)
    }
    return navigateTo(
      {
        path: withLeadingSlash(joinURL(region, url)),
        query: to.query,
      },
      {
        redirectCode: 301,
        external: true,
      },
    )
  }
  catch {
    return showError({
      statusCode: 404,
    })
  }
})
